<template>
  <!--begin::Advance Table Widget 9-->
  <div>
    <b-row>
      <b-col cols="12">
        <div class="card-toolbar mt-6 d-flex justify-content-end">
          <button class="btn btn-primary font-weight-bolder font-size-sm mr-4" :disabled="!can_upload_bgmax" @click.prevent="create_file_clicked">
            <i class="menu-icon flaticon2-file"></i>
            {{ $t('INVOICE.CREATE_BGMAX') }}
          </button>
        </div>

      </b-col>
    </b-row>
    

    <b-row class="mr-2">
      <b-col cols="12">
        <p class="error-info-text" style="text-align: right;" v-if="!can_upload_bgmax">{{ $t('INVOICE.MISSING_ACCOUNT_NO') }}</p>
      </b-col>
      
    </b-row>

    

    <!--begin::Body-->
    <div class="pt-0 pb-3 table-responsive" style="min-height: 400px;">
      <div class="d-sm-flex align-items-end mb-4">
        <div class="d-sm-flex justify-content-end align-items-center w-100">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 250]"
            v-model="per_page"
          />

          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="per_page"
            aria-controls="bgmax-table"
          ></b-pagination>
        </div>
      </div>

      <b-table
        id="bgmax-table"
        :fields="headers"
        :items="items"
        :per-page="per_page"
        :current-page="currentPage"
        class="table-striped"
        head-variant="light"
      >
        <template #cell(show_details)="row">
          <div class="d-flex justify-content-end">
            <a class="btn btn-icon btn-light btn-sm mx-2" @click="row.toggleDetails">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <!-- <a class="btn btn-icon btn-light btn-sm mx-2" @click="deleteItemClicked(row)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a> -->
          </div>
        </template>
        <template #row-details="row">
          <b-card style="cursor: default;" class="display-flex">
            <h4 class="mb-6">Transaktioner</h4>
            <b-table
              :fields="transactionHeaders"
              :items="row.item.bgmax_transactions"
              head-variant="light"
            />
            <b-button size="sm" @click="row.toggleDetails">Göm detaljer</b-button>
          </b-card>
        </template>
      </b-table>
    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<style lang="scss" scoped>


.error-info-text {
  color: rgb(255, 84, 84);
  font-style: italic;
}

</style>

<script>
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import axios from 'axios';


export default {
  name: 'bgmax-table',
  props: ['items'],
  emits: ['create_file_clicked'],
  computed: {
    ...mapGetters(['currentCompanyId', 'companies']),
    can_upload_bgmax() {
      return this.company.account_no && this.company.account_no.length > 6;
    }
  },
  mixins: [ toasts ],
  components: {
    
  },
  watch: {},
  async mounted() {
    await this.load_company_settings();
  },
  methods: {
    async refresh_settings() {
      await this.load_company_settings();
    },
    deleteItemClicked(row) {},
    create_file_clicked() {
      this.$emit('create_file_clicked');
    },

    async load_company_settings() {
      try {
        const res = await axios.get(`/company/admin?company_id=${this.currentCompanyId}`)

        if (res.status === 200) {
          this.company = res.data;
        }
      }
      catch (err) {
        console.error('load_company_settings error', err);

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMPANY.UNABLE_GET'));
      
      }
    }
  },
  data() {
    return {
      company: {},
      per_page: 100,
      currentPage: 1,
      headers: [
        {
          key: 'file.name',
          label: 'Filnamn',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Datum',
          sortable: true
        },
        {
          key: 'show_details',
          label: '',
          sortable: false
        }
      ],
      transactionHeaders: [
        {
          key: 'invoice_id',
          label: 'Fakturanummer'
        },
        {
          key: 'ocr',
          label: 'OCR'
        },
        {
          key: 'amount_paid',
          label: 'Betalt belopp'
        },
        /*{
          key: 'invoice.total_sek',
          label: 'Faktura totalbelopp'
        },
        {
          key: 'full_payment',
          label: 'Fullt betalad',
          formatter: (_, __, item) => {
            return item.invoice.total_sek - item.amount_paid > 0 ? 'Nej' : 'Ja';
          }
        }*/
      ]
    };
  }
};
</script>
