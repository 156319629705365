<template>

  <b-modal ref="pdf-modal" v-model="visible" :title="$t('INVOICE.PDF_TITLE')">

    <b-card v-if="visible">

      <b-form-group :label="$t('INVOICE.COUNT_SELECTED')" label-for="count">
        <p style="font-weight: bold;">{{ count }}</p>
      </b-form-group>

      <b-form-group :label="$t('INVOICE.PAYMENT_STATUS')" label-for="payment_status">

        <b-form-input
          id="payment_status"
          v-model="payment_status"
          autocomplete="off"
          :placeholder="$t('INVOICE.PAYMENT_STATUS')"
          :disabled="true"
        />
      </b-form-group>

      <b-form-group :label="$t('COMMON.CREATED_FROM')" label-for="created_from">
        <b-form-input
          id="created_from"
          v-model="local_options.created_from"
          autocomplete="off"
          :placeholder="$t('COMMON.CREATED_FROM')"
          :disabled="true"
        />
      </b-form-group>

      <b-form-group :label="$t('COMMON.CREATED_TO')" label-for="created_to">
        <b-form-input
          id="created_to"
          v-model="local_options.created_to"
          autocomplete="off"
          :placeholder="$t('COMMON.CREATED_TO')"
          :disabled="true"
        />
      </b-form-group>

      <b-form-group :label="$t('INVOICE.PDF_TEMPLATES')" label-for="email_template">
        <b-form-select
          id="email_template"
          v-model="local_options.template_id"
          :options="templates"
          :state="template_id_state"
        />
      </b-form-group>

      <b-form-group :label="$t('INVOICE.ORDERBY')" label-for="orderBy">

        <b-form-select
          id="orderBy"
          v-model="local_options.order_by"
          :options="order_by_options"
        />

      </b-form-group>

      <div class="alert alert-custom alert-notice alert-light-primary fade show" role="alert">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">{{$t('INVOICE.JOB_INFO')}}</div>
      </div>

    </b-card>

    <template #modal-footer>
      <b-button ref="cancel" variant="secondary" @click="cancel()">{{$t('COMMON.CANCEL')}}</b-button>
      <b-button ref="confirm" variant="success" @click="confirm()">{{$t('INVOICE.RUN_JOB')}}</b-button>
    </template>

  </b-modal>

</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { validate_state } from '@/core/services/validations.service';

export default {
  name: 'InvoicePDFOptionsModal',
  mixins: [ toasts ],
  components: {

  },
  props: {
    options: {
      default: {
        order_by: 'zipcode'
      }
    },
    count: {
      default: 0
    }
  },
  emits: ['cancel', 'confirm'],
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      local_options: {
        template_id: {
          required,
        }
      }
    };
  },
  watch: {
    options: {
      deep: true,
      handler(val) {
        this.local_options = {...val};
      }
    }
  },
  computed: {
    ...mapGetters(['currentCompanyId']),
    payment_status() {
      switch (this.local_options.payment_status) {
        case 'LATE': return this.$t('INVOICE.LATE');
        case 'PAID': return this.$t('INVOICE.PAID');
        case 'WAIT': return this.$t('INVOICE.WAIT');
        case 'NOT_PAID': return this.$t('INVOICE.NOT_PAID');
        case 'PARTIALLY_PAID': return this.$t('INVOICE.PARTIALLY_PAID');
      }

      return this.$t('COMMON.ALL');
    },
    template_id_state() {
      return validate_state({ dirty: this.v$.local_options.template_id.$dirty, error: this.v$.local_options.template_id.$error })
    },
  },

  methods: {
    async show() {
      this.visible = true;

      this.$refs['pdf-modal'].show();
    },
    hide() {
      this.visible = false;
    },
    cancel() {
      this.visible = false;
      this.$emit("cancel");
    },
    async confirm() {
      const valid = await this.v$.$validate();

      if (!valid) {
        this.v$.$touch()
        return
      }

      this.visible = false;
      this.$emit("confirm", this.local_options);

    },
    async get_count() {
      delete this.local_options.count;
      try {
        const res = await axios.post(`/invoice/pagination/count`, { options: this.local_options });
        if (res.status === 200) {
          this.local_options.count = res.data.count;
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_GET_COUNT'));
      }
    },

    async fetch_templates() {
      try {
        const res = await axios.get(`/invoice_template`);
        if (res.status === 200) {
          this.templates = res.data.map(item=>({value: item.id, text: item.name}));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_LIST_TEMPLATES'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_LIST_TEMPLATES'));
      }
    }
  },

  async mounted() {
    if (this.options) {
      this.local_options = { ...this.options };
    }

    await this.get_count();
    await this.fetch_templates();
  },

  data() {
    return {
      local_options: {},
      visible: false,
      templates: [],
      order_by_options: [
        { value: 'zipcode', text: this.$t('INVOICE.ZIPCODE') },
        { value: 'invoice_id', text: this.$t('INVOICE.INVOICE_NO') }
      ]
    };
  },
};
</script>
<style scoped>
</style>

